import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const CardContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`;

const CardRow = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0;
  padding-left: 0;


  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
    display: block;
  }
`;

const CardWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 200px;

  @media (min-width:768px) {
    flex-direction: row;
    min-height: 483px;
  }
`;

const CardImageWrapper = styled.div`
@supports (display:-webkit-box) or (display:-webkit-flex) or (display:-ms-flexbox) or (display:flex) {
  flex: 1 1 0%;
}

@media (min-width:768px) {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  justify-content: center;
}
`;

const FeatureCardImg = styled(Img)`
max-height: 100% !important;
height: 100% !important;
width: 100% !important;

  @media (max-width:768px) {
  min-height: 160px;
  }
`;

const CardContent = styled.div`
  padding: 24px;
  text-align: left;

  @supports (display:-webkit-box) or (display:-webkit-flex) or (display:-ms-flexbox) or (display:flex) {
    flex: 1 1 0%;
  }

  @media (min-width:768px) {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: center;
    margin: 32px 0;
    padding: 48px;
  }
  ol {
    list-style-type: decimal;
    column-gap: 64px;
    padding-left: 32px;
    color: inherit;
    margin: 0px;
  }
  li {
    font-size: 18px;
    position: relative;
    padding: 4px 0px;
    break-inside: avoid;
    width: 100%;
    margin: 0px 0px 8px;
    font-weight: 300;
    line-height: 1.6;
    color: rgb(45, 47, 61);
  }
`;


const ListCardsSection = styled.section`

`;


const FeatureCardLinks = props => (
<ListCardsSection>
        <CardContainer>
        <CardRow>
            <CardWrapper>
            <CardImageWrapper>
                <FeatureCardImg fixed={props.cardimage} />
                </CardImageWrapper>
                <CardContent>
                    <h2>{props.cardheading}</h2>
                    {props.cardtext &&
                    <p>{props.cardtext}</p>
                    }
                    <ul>
                    {props.cardbullets.map((item) => (
                        <li><Link to={item.bulletlink}>{item.bullet}</Link>:  {item.bullettext}</li>
                    ))}
                    </ul>
                </CardContent>
            </CardWrapper>
        </CardRow>
        </CardContainer>
</ListCardsSection>
);
export default FeatureCardLinks; 

