import React from 'react'
import styled from 'styled-components'


const OfferSection = styled.section`
background-color: ${props => props.backgroundcolor ? 'rgb(252, 252, 252)' : 'rgb(0, 79, 239)'};
padding: 64px 0px;
`;

const OfferDiv = styled.div`
display: inherit;

width: 100%;
max-width: 1312px;
margin: 0 auto;
    display: flex;
    justify-content: center;
`;

const OfferContainer= styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    justify-content: center;

    @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
    }

    @media only screen and (min-width:62em) {
        flex-basis: 75%;
        max-width: 75%;
        display: flex;
        justify-content: center;
      }

`;
const OfferContent= styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
    }
    @media only screen and (min-width:62em) {
        display: block;
    }
    @media (min-width:576px) {
        font-size: 18px;
      }
    p {
        color: ${props => props.backgroundcolor ? 'rgb(45, 47, 61)' : 'rgb(255, 255, 255)'};
        font-size: 16px;
        font-weight: 300;
        line-height: 1.6;
        margin: 0px 0px 24px;
        font-size: 18px;
    }
    strong {
        font-weight: 500;
    }
`;
const OfferHeader = styled.h2`
    text-align: center;
    color: ${props => props.backgroundcolor ? 'rgb(45, 47, 61)' : 'rgb(255, 255, 255)'};
`;



const CallOut = props => (
    <OfferSection backgroundcolor={props.background}>
        <OfferDiv>
            <OfferContainer>
                <OfferContent backgroundcolor={props.background}>
                <OfferHeader backgroundcolor={props.background}>{props.calloutheader}</OfferHeader>
                    {props.calloutlist &&
                        <React.Fragment>
                            {props.calloutlist.map(item => (
                            <p><strong>{item.listitem}</strong>:&nbsp;{item.listtext}</p>
                            ))}   
                        </React.Fragment> 
                    }
                </OfferContent>
            </OfferContainer>
        </OfferDiv>
    </OfferSection>
  )
  
  
  
export default CallOut