import React, { useState } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/seo/mainseo'
import PageBannerOG from '../components/pagebanner/pagebanner'
import MainNav from '../components/navigation/mainnav'
import PageBannerMessage from '../components/pagebanner/pagebannermessage'
import Container from '../components/container'
import CenteringDiv from '../components/centeringdiv'
import ContentContainer from '../components/contentcontainer'
import ContentBlock from '../components/contentblock'
import ContentBlockBackground from '../components/contentblockbackground'
import FeatureCardList from '../components/cards/featurecardlist'
import FeatureListCard from '../components/cards/featurelistcard'
import FeatureCardLinks from '../components/cards/featurecardlinks'
import LanguageSchoolCard from '../components/cards/languageschoolcard'
import Card from '../components/cards/linkcard'
import CardDeck from '../components/cards/carddeck'
import PageBlock from '../components/pageblock'
import CallOut from '../components/callout'
import PageContact from '../components/forms/pagecontact-sf'
import BreadCrumbs  from '../components/breadcrumbs'
import BreadCrumbsTopLevel  from '../components/breadcrumbstoplevel'
import CourseTable  from '../components/coursetable/coursetablev1'
import IntroDiv from '../components/introdiv'

const LanguagePagev2 = ({ data, html }) => {
  const { markdownRemark: post } = data
  var delink = "de";
  var enlink = "en";
  const metaUrlalt="https://www.berlitz-augsburg.de/"
  const metaUrl="https://www.berlitz-augsburg.de"

  if (post.frontmatter.language === "de"){
    delink = `${metaUrl}${post.fields.slug}`
    enlink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
  } else if (post.frontmatter.language === "en") {
    delink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
    enlink = `${metaUrl}${post.fields.slug}`
  }
  const [course, setCourse] = useState("");
 

  return (
    <Layout currentlang={post.frontmatter.language} langlinkde={delink} langlinken={enlink}>
      <Seo language={post.frontmatter.language}
           pageslug={post.fields.slug}
           imgtitle={post.frontmatter.title}
           imgtitleen={post.frontmatter.imagetitle}
           title={post.frontmatter.seo.metatitle}
           description={post.frontmatter.seo.metadescription}
           lastbreadcrumb={post.frontmatter.lastbreadcrumb}
           pagetype={post.frontmatter.templateKey}
           altlanglink={post.frontmatter.seo.altlanguagelink}
           hreflangalt={post.frontmatter.seo.hreflangalt}
      />
      {post.frontmatter.language === "de" &&
        <PageBannerOG title={post.frontmatter.title} alt={post.frontmatter.displaytitle} isHome={post.frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language}/>
          <PageBannerMessage supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.language === "en" &&
        <PageBannerOG title={post.frontmatter.imagetitle} alt={post.frontmatter.displaytitle} isHome={post.frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language}/>
          <PageBannerMessage supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.toplevelpage === true &&
      <BreadCrumbsTopLevel current={post.frontmatter.lastbreadcrumb}
                   currentlang={post.frontmatter.language}
                   breadcrumbcolor={post.frontmatter.breadcrumbcolor} 
      />
      }
      {post.frontmatter.toplevelpage === false &&
      <BreadCrumbs breadcrumbs={post.frontmatter.breadcrumbs} 
                   current={post.frontmatter.lastbreadcrumb}
                   currentlang={post.frontmatter.language}
                   breadcrumbcolor={post.frontmatter.breadcrumbcolor}  
      />
      }
      <section role="main">     
        {post.frontmatter.leadinabout &&
          <ContentBlockBackground>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.leadinheading &&
                    <h2>{post.frontmatter.leadinheading}</h2>
                  }
                  {post.frontmatter.leadinabout.map(item => (
                    <p>{item.paragraph}</p>
                  ))}
                </ContentContainer>
              </CenteringDiv>  
            </Container>
          </ContentBlockBackground>
        }
        {post.frontmatter.introcontentblocksabout &&
          <ContentBlockBackground>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.introheading &&
                    <h2>{post.frontmatter.introheading}</h2>
                  }
                  {post.frontmatter.introcontentblocksabout.map(item => (
                    <p>{item.introparagraph}</p>
                  ))}
                </ContentContainer>
              </CenteringDiv>  
            </Container>
          </ContentBlockBackground>
        }
        {post.frontmatter.introcontent &&
          <ContentBlock background={post.frontmatter.introbackground}>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.introcontent.heading &&
                    <h2>{post.frontmatter.introcontent.heading}</h2>
                  }
                  {post.frontmatter.introcontent.introparagraphs &&
                    <React.Fragment>
                      {post.frontmatter.introcontent.introparagraphs.map(item => (
                        <p>{item.paragraph}</p>
                        ))}
                    </React.Fragment>
                  }
                  {post.frontmatter.introcontent.subheader &&
                    <h3>{post.frontmatter.introcontent.subheader}</h3>
                  }
                  {post.frontmatter.introcontent.list &&
                    <div className="content-list">
                       <ul>
                      {post.frontmatter.introcontent.list.map(item => (
                       <li>{item.listitem}</li>                
                      ))}
                      </ul>
                    </div>
                  }
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.pagecontentblocks  &&
          <ContentBlock>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.pagecontentblocks.map(item => (
                    <PageBlock         
                      heading={item.heading}
                      text={item.text}
                      aligncenter={true}
                    />
                  ))}
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.cards &&
          <ContentBlock background={post.frontmatter.background}>
            <Container>
              {post.frontmatter.cardsintro &&
                <CenteringDiv>
                  <ContentContainer>
                    <IntroDiv>
                      <h2>{post.frontmatter.cardsintro.heading}</h2>
                      {post.frontmatter.cardsintro.paragraphs &&
                        <React.Fragment>
                          {post.frontmatter.cardsintro.paragraphs.map(item => (
                          <p>{item.paragraph}</p>
                          ))}  
                        </React.Fragment>
                      }
                    </IntroDiv>
                  </ContentContainer>
                </CenteringDiv>
              }
                <CardDeck>
                  {post.frontmatter.cards.map(item => (
                    <Card cardcontent={item.text}
                          cardtitle={item.cardtitle}
                          url={item.link}
                          button={item.button}
                          cardimg={item.image.childImageSharp.fixed}
                          currentlanguage={post.frontmatter.language}
                          cardh3={item.cardh3} 
                    />
                  ))}
                </CardDeck>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.languageschoolcard &&
                  <ContentBlock>
                  <Container>
                      <LanguageSchoolCard cardheading={post.frontmatter.languageschoolcard.cardheading}
                                        cardsubtitle={post.frontmatter.languageschoolcard.cardsubtitle}
                                        cardaddress1={post.frontmatter.languageschoolcard.cardaddress1}
                                        cardaddress2={post.frontmatter.languageschoolcard.cardaddress2}
                                        cardbullets={post.frontmatter.languageschoolcard.cardbullets}
                                        cardimage={post.frontmatter.languageschoolcard.cardimage.childImageSharp.fixed}
                                        currentlanguage={post.frontmatter.language}
                      />
                  </Container>
              </ContentBlock>
      
        }
        {post.frontmatter.cardstwo &&
            <Container style={{ paddingBottom: "64px" }}>
              {post.frontmatter.cardsintrotwo &&
                <CenteringDiv style={{marginBottom: '32px', marginTop: '32px'}}>
                  <ContentContainer>
                    <IntroDiv>
                      <h2>{post.frontmatter.cardsintrotwo.heading}</h2>
                      {post.frontmatter.cardsintrotwo.paragraphs &&
                        <React.Fragment>
                          {post.frontmatter.cardsintrotwo.paragraphs.map(item => (
                          <p>{item.paragraph}</p>
                          ))}  
                        </React.Fragment>
                      }
                    </IntroDiv>
                  </ContentContainer>
                </CenteringDiv>
              }
                <CardDeck>
                  {post.frontmatter.cardstwo.map(item => (
                    <Card cardcontent={item.text}
                          cardtitle={item.cardtitle}
                          url={item.link}
                          button={item.button}
                          cardimg={item.image.childImageSharp.fixed}
                          currentlanguage={post.frontmatter.language} 
                    />
                  ))}
                </CardDeck>
            </Container>
        }
        {post.frontmatter.featurecardlist &&        
          <ContentBlock>
            <Container>
                <FeatureCardList cardtitle={post.frontmatter.featurecardlist.cardtitle}
                                 carditems={post.frontmatter.featurecardlist.listitems}
                                 cardimage={post.frontmatter.featurecardlist.featurecardimage.childImageSharp.fixed}
                                 imageposition={post.frontmatter.featurecardlist.imageposition}
                                 buttons={post.frontmatter.featurecardlist.buttons}
                                 languageselect={post.frontmatter.featurecardlist.languageselect}
                                 singlebutton={post.frontmatter.featurecardlist.singlebutton}
                                 singlebuttonlink={post.frontmatter.featurecardlist.singlebuttonlink}
                />
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.courses  &&
          <React.Fragment>
            <ContentBlock>
              <Container>
                <CenteringDiv>
                  <CourseTable courses={post.frontmatter.courses} 
                              courseevenings={post.frontmatter.courseevenings}
                              coursemornings={post.frontmatter.coursemornings}   
                              currentlanguage={post.frontmatter.language} 
                              assignCourse={setCourse}
                  />
                </CenteringDiv>
              </Container>
            </ContentBlock>
          </React.Fragment>
        }
        {post.frontmatter.pagebody &&
          <ContentBlock background={post.frontmatter.background}>
            <Container>
              <CenteringDiv>
                <ContentContainer dangerouslySetInnerHTML={{ __html: post.html }}/>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.calloutheader &&
          <React.Fragment>
            {post.frontmatter.calloutlist ? (
              <CallOut calloutheader={post.frontmatter.calloutheader}
                       calloutlist={post.frontmatter.calloutlist}
              />
              ) : (
              <CallOut calloutheader={post.frontmatter.calloutheader} />
            )}
          </React.Fragment>
        }
        {post.frontmatter.calloutheader2 &&
          <React.Fragment>
            {post.frontmatter.calloutlist2 ? (
              <CallOut calloutheader={post.frontmatter.calloutheader2}
                       calloutlist={post.frontmatter.calloutlist2}
                       background={true}
              />
              ) : (
              <CallOut calloutheader={post.frontmatter.calloutheader2} />
            )}
          </React.Fragment>
        }
        {post.frontmatter.featurelistcard &&
            <ContentBlock>
                <Container>
                    <FeatureListCard card={post.frontmatter.featurelistcard}/>
                </Container>
            </ContentBlock>
        }
        {post.frontmatter.featurecardlinks &&
            <ContentBlock>
                <Container>
                    <FeatureCardLinks cardheading={post.frontmatter.featurecardlinks.cardheading}
                                      cardtext={post.frontmatter.featurecardlinks.cardtext}
                                      cardbullets={post.frontmatter.featurecardlinks.cardbullets}
                                      cardimage={post.frontmatter.featurecardlinks.cardimage.childImageSharp.fixed}
                    />
                </Container>
            </ContentBlock>
        }  
          <PageContact currentlang={post.frontmatter.language}
                       leadsource={post.fields.slug}
                       selectedcourse={course}
                       pagetype={post.frontmatter.pagetype}
                       delanguageinterest={post.frontmatter.delanguageinterest}
                       enlanguageinterest={post.frontmatter.enlanguageinterest}
                       anchor="kursanfrage"
                       anchoren="course-inquiry"  
          />      
      </section>
    </Layout>
  )
}

export default LanguagePagev2

export const pageQuery = graphql`
  query LanguagePagev2ByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        templateKey
        pagetype
        delanguageinterest
        enlanguageinterest
        subfolder
        toplevelpage
        language
        seo {
          metatitle
          metadescription
          altlanguagelink
          hreflangalt
        }
        title
        imagetitle       
        displaytitle
        supertitle
        breadcrumbs {
          crumbitem
          link
        }
        lastbreadcrumb
        breadcrumbcolor
        background
        cardwithlink
        pagebody
        leadinheading
        leadinabout {
          paragraph
        }
        introheading
        introcontentblocksabout {
          introparagraph
        }
        introcontent {
          heading
          introparagraphs {
            paragraph
          }
          subheader
          list {
            listitem
          }
        }
        courses
        calloutheader
        calloutlist {
          listitem
          listtext
        }
        calloutheader2
        calloutlist2 {
          listitem
          listtext
        }
        pagecontentblocks {
          heading
          text
        }
        featurecard {
          cardtitle
          cardtext
          cardlink
          cardbutton
          cardimage {
            childImageSharp {
              fixed(width: 500, height: 500, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        cardsintro {
          heading
          paragraphs {
            paragraph
          }
        }
        cardsintrotwo {
          heading
          paragraphs {
            paragraph
          }
        }
        cards {
          image {
            childImageSharp {
              fixed(width: 480, height: 320, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          cardtitle
          cardh3
          link
          button
          text
        }
        cardstwo {
          image {
            childImageSharp {
              fixed(width: 480, height: 320, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          cardtitle
          link
          button
          text
        }
        featurecardlist {
          cardtitle
          listitems {
            listitem
          }
          buttons {
            button
            buttonlink
          }
          singlebutton
          singlebuttonlink
          imageposition
          languageselect
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurelistcard {
          cardtitle
          cardtext1
          cardtext2
          cardtext3
          cardtext4
          cardtext5
          cardimage {
            childImageSharp {
              fixed(width: 500, height: 500, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        featurecardlinks {
          cardheading
          cardtext
          cardbullets {
            bullet
            bulletlink
            bullettext
          }
          cardimage {
            childImageSharp {
              fixed(width: 500, height: 500, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        languageschoolcard {
            cardheading
            cardsubtitle
            cardaddress1
            cardaddress2
            cardbullets {
              bullet
              bulletlink
              bullettext
            }
            cardimage {
              childImageSharp {
                fixed(width: 500, height: 500, quality: 80) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        buttoncards {
          link
          text
        }
      }
    }
  }
  
`
